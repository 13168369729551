<template >
  <section>
    <div v-if="stage == 0">
      <p class="mb-1">目前您的購物車中沒有商品...</p>
      <p>請單擊下面的"繼續購物"</p>
    </div>

    <div v-if="stage == 1">
      <CartItem
        v-for="(item, index) in computedData"
        :key="item.item_id"
        :value="item"
        :index="index"
        :storeId="storeId"
        :providerId="providerId"
        :readApi="readApi"
        :handleRemoveItem="handleRemoveItem"
        @changeTotal="getChildrenTotal"
      >
      </CartItem>
    </div>

    <div v-if="stage == 2">
      <OrderConfirmCard
        v-for="(item, index) in computedData"
        :key="index"
        :value="createConfirmCard(item)"
      ></OrderConfirmCard>

      <v-textarea class="mt-8" name="comment" label="備註" outlined></v-textarea>
    </div>

    <number-bar
      v-if="stage != 0"
      readonly
      title="預估總計"
      unit="元"
      :value="amount"
      type="secondary"
      :fixed="3"
    ></number-bar>
  </section>
</template>

<script>
import ItemMixin from '@/components/form/custom/ItemMixin.js'
import amountTotal from "@/mixins/amountTotal.js"
import CartItem from "./cartItem.vue"
import OrderConfirmCard from "@/modules/base/components/order/orderConfirmCard.vue"
import numberBar from "@/modules/base/components/bar/numberBar.vue"
import discountMixins from "@/mixins/discount.js";
export default {
    mixins: [ItemMixin, amountTotal, discountMixins],
    props: {
      props: Object
    },
    components: {
      CartItem,
      numberBar,
      OrderConfirmCard
    },
    data: () => ({
      data : [],
    }),
    watch : {
      propsValue : {
        immediate: true,
        deep: true,
        handler() {
            if(!this.propsValue) return ;

            this.data = this.$eagleLodash.cloneDeep(this.propsValue)
            if(!this.data || this.data.length == 0) {
              this.changeStage(0)
            }

        },
      },
    },
    computed: {
      computedData() {
        const computedData = this.data.map(item => {
          return {
            ...item,
            price: this.getDiscountPrice({
              discount_config: item.discount_config,
              price: item.product_price,
              quantity: item.quantity
            })
          }
        })

        return computedData
      },
      storeId() {
        return this.$store.getters['member/storeId']
      },
      providerId() {
        return this.$route.params.providerId
      },
      changeStage() {
        return this.props.changeStage
      },
      stage() {
        if(!this.props) return 1
        return this.props.stage
      },
      readApi() {
        return this.props.readApi
      },
    },
  methods: {
      async handleRemoveItem(index) {
        await this.$nextTick()
        await this.getChildrenTotal()
      },
      createConfirmCard(item) {
        return {
          product_name: item.product_name,
          shipping_unit: item.shipping_unit,
          calculate_unit: item.calculate_unit,
          per_shipping_count: item.per_shipping_count,
          price: item.price,
          order_count: this.$helper.reverseShippingCount(item.quantity, item.shipping_unit)
        }
      },

    },
}
</script>

